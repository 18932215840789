import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import PageItem from "../components/pageItem"
import SEO from "../components/seo"
import Img from "gatsby-image"

const SecondPage = (props) => (
  <Layout pageInfo={{ pageName: "feder" }}>
    <SEO title="feder" />
    {/* <h1>Ather</h1> */}
    {/* <p>Welcome to page 2</p> */}
    {/* <Link to="/">Go back to the homepage</Link> */}
    <div id="content" className="pages-placeholder  horizontal-scroll-wrapper" 
    style={{width: `3400vw`,background:"#813D30"}}
    >
      {/* <div className="pages"> */}

        <PageItem  ><Img fluid={props.data.feder01.childImageSharp.fluid} /></PageItem>
        <PageItem  ><Img fluid={props.data.feder02.childImageSharp.fluid} /></PageItem>
        <PageItem  ><Img fluid={props.data.feder03.childImageSharp.fluid} /></PageItem>
        <PageItem  ><Img fluid={props.data.feder04.childImageSharp.fluid} /></PageItem>
        <PageItem  ><Img fluid={props.data.feder05.childImageSharp.fluid} /></PageItem>
        <PageItem  ><Img fluid={props.data.feder06.childImageSharp.fluid} /></PageItem>
        <PageItem  ><Img fluid={props.data.feder07.childImageSharp.fluid} /></PageItem>
        <PageItem  ><Img fluid={props.data.feder08.childImageSharp.fluid} /></PageItem>
        <PageItem  ><Img fluid={props.data.feder09.childImageSharp.fluid} /></PageItem>
        <PageItem  ><Img fluid={props.data.feder10.childImageSharp.fluid} /></PageItem>
        <PageItem  ><Img fluid={props.data.feder11.childImageSharp.fluid} /></PageItem>
        <PageItem  ><Img fluid={props.data.feder12.childImageSharp.fluid} /></PageItem>
        <PageItem  ><Img fluid={props.data.feder13.childImageSharp.fluid} /></PageItem>
        <PageItem  ><Img fluid={props.data.feder14.childImageSharp.fluid} /></PageItem>
        <PageItem  ><Img fluid={props.data.feder15.childImageSharp.fluid} /></PageItem>
        <PageItem  ><Img fluid={props.data.feder16.childImageSharp.fluid} /></PageItem>
        <PageItem  ><Img fluid={props.data.feder17.childImageSharp.fluid} /></PageItem>
        <PageItem  ><Img fluid={props.data.feder18.childImageSharp.fluid} /></PageItem>
        <PageItem  ><Img fluid={props.data.feder19.childImageSharp.fluid} /></PageItem>
        <PageItem  ><Img fluid={props.data.feder20.childImageSharp.fluid} /></PageItem>
        <PageItem  ><Img fluid={props.data.feder21.childImageSharp.fluid} /></PageItem>
        <PageItem  ><Img fluid={props.data.feder22.childImageSharp.fluid} /></PageItem>
        <PageItem  ><Img fluid={props.data.feder23.childImageSharp.fluid} /></PageItem>
        <PageItem  ><Img fluid={props.data.feder24.childImageSharp.fluid} /></PageItem>
        <PageItem  ><Img fluid={props.data.feder25.childImageSharp.fluid} /></PageItem>
        <PageItem  ><Img fluid={props.data.feder26.childImageSharp.fluid} /></PageItem>
        <PageItem  ><Img fluid={props.data.feder27.childImageSharp.fluid} /></PageItem>
        <PageItem  ><Img fluid={props.data.feder28.childImageSharp.fluid} /></PageItem>
        <PageItem  ><Img fluid={props.data.feder29.childImageSharp.fluid} /></PageItem>



{/* <div>item 1</div>
  <div>item 2</div>
  <div>item 3</div>
  <div>item 4</div>
  <div>item 5</div>
  <div>item 6</div>
  <div>item 7</div>
  <div>item 8</div> */}










        {/* <div className="page 01">page01</div>
      <div className="page 02">page02</div> */}
      {/* </div> */}
    </div>
  </Layout>
)

export default SecondPage

export const fluidImage = graphql`
fragment fluidImage on File {
  childImageSharp {
    fluid(maxWidth: 1000) {
      ...GatsbyImageSharpFluid
    }
  }
}
`;

export const pageQuery = graphql`
  query {
    feder01: file(relativePath: { eq: "feder_1.jpg" }) {
      ...fluidImage
    }
    feder02: file(relativePath: { eq: "feder_2.jpg" }) {
      ...fluidImage
    }
    feder03: file(relativePath: { eq: "feder_3.jpg" }) {
      ...fluidImage
    }
    feder04: file(relativePath: { eq: "feder_4.jpg" }) {
      ...fluidImage
    }
    feder05: file(relativePath: { eq: "feder_5.jpg" }) {
      ...fluidImage
    }
    feder06: file(relativePath: { eq: "feder_6.jpg" }) {
      ...fluidImage
    }
    feder07: file(relativePath: { eq: "feder_7.jpg" }) {
      ...fluidImage
    }
    feder08: file(relativePath: { eq: "feder_8.jpg" }) {
      ...fluidImage
    }
    feder09: file(relativePath: { eq: "feder_9.jpg" }) {
      ...fluidImage
    }
    feder10: file(relativePath: { eq: "feder_10.jpg" }) {
      ...fluidImage
    }
    feder11: file(relativePath: { eq: "feder_11.jpg" }) {
      ...fluidImage
    }
    feder12: file(relativePath: { eq: "feder_12.jpg" }) {
      ...fluidImage
    }
    feder13: file(relativePath: { eq: "feder_13.jpg" }) {
      ...fluidImage
    }
    feder14: file(relativePath: { eq: "feder_14.jpg" }) {
      ...fluidImage
    }
    feder15: file(relativePath: { eq: "feder_15.jpg" }) {
      ...fluidImage
    }
    feder16: file(relativePath: { eq: "feder_16.jpg" }) {
      ...fluidImage
    }
    feder17: file(relativePath: { eq: "feder_17.jpg" }) {
      ...fluidImage
    }
    feder18: file(relativePath: { eq: "feder_18.jpg" }) {
      ...fluidImage
    }
    feder19: file(relativePath: { eq: "feder_19.jpg" }) {
      ...fluidImage
    }
    feder20: file(relativePath: { eq: "feder_20.jpg" }) {
      ...fluidImage
    }
    feder21: file(relativePath: { eq: "feder_21.jpg" }) {
      ...fluidImage
    }
    feder22: file(relativePath: { eq: "feder_22.jpg" }) {
      ...fluidImage
    }
    feder23: file(relativePath: { eq: "feder_23.jpg" }) {
      ...fluidImage
    }
    feder24: file(relativePath: { eq: "feder_24.jpg" }) {
      ...fluidImage
    }
    feder25: file(relativePath: { eq: "feder_25.jpg" }) {
      ...fluidImage
    }
    feder26: file(relativePath: { eq: "feder_26.jpg" }) {
      ...fluidImage
    }
    feder27: file(relativePath: { eq: "feder_27.jpg" }) {
      ...fluidImage
    }
    feder28: file(relativePath: { eq: "feder_28.jpg" }) {
      ...fluidImage
    }
    feder29: file(relativePath: { eq: "feder_29.jpg" }) {
      ...fluidImage
    }
  }
`